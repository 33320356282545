import React from "react";
import { Link } from "gatsby";
const _ = require("lodash");

export default ({ tags, path }) => {
  return (
    <div className="h-full w-full">
      <div className="text-grey-darker font-semibold text-sm tracking-wide mb-2">
        TAGS
      </div>
      {path === "/" ? (
        <Link to={`/`} className="no-underline" key={`/`}>
          <div className="bg-white py-2">
            <span className="px-2 py-4 mr-1 text-blue-darkest font-medium text-sm">
              All
            </span>
          </div>
        </Link>
      ) : (
        <Link to={`/`} className="no-underline" key={`/`}>
          <div className="py-2">
            <span className="px-2 py-4 mr-1 text-blue-darkest font-medium text-sm">
              All
            </span>
          </div>
        </Link>
      )}

      {tags.map(tag => {
        const tagName = tag.fieldValue;
        const tagCount = tag.totalCount;
        const pathTag = path.substr(5);
        const shouldHighlight = pathTag === _.kebabCase(tagName);
        return shouldHighlight ? (
          <Link
            to={`tag/${tagName}`}
            className="no-underline"
            key={`${tagName}`}
          >
            <div className="bg-white py-2 ">
              <span className="px-2 mr-1 text-blue-darkest font-medium text-sm">
                {tagName}
              </span>
            </div>
          </Link>
        ) : (
          <Link
            to={`tag/${tagName}`}
            className="no-underline"
            key={`${tagName}`}
          >
            <div className=" py-2 ">
              <span className="px-2 mr-1 text-blue-darkest font-medium text-sm">
                {tagName}
              </span>
            </div>
          </Link>
        );
      })}
    </div>
  );
};
