import PropTypes from "prop-types";
import React from "react";
import Header from "./header/";
import Footer from "./footer";
import Helmet from "react-helmet";
import TagList from "./tags-section/";
import "./index.css";

import favicon from "../images/favicon.ico";

const container = "flex flex-col h-full border-box";
const fullHeight = "h-full";
const body = "flex flex-col h-full m-0";
export default ({ children, tags, path }) => {
  return (
    <div className={container}>
      <Helmet
        link={[{ rel: "shortcut icon", type: "image/png", href: `${favicon}` }]}
      >
        <html className={fullHeight} />
        <title>GrokOn</title>
        <meta name="description" content="Idiotic squiggles of a nobody" />
        <body className={body} />
      </Helmet>
      <Header />
      {tags && tags.length > 0 ? (
        <div className="flex-1 bg-grey-lighter">
          <div className="inline-flex h-full w-full px-4 py-2">
            <div className="mx-auto w-5/6 inline-block my-2 mx-2 bg-white ">
              {children}
            </div>
            <div className="w-1/6 inline-block my-2 mx-2 ">
              <TagList tags={tags} path={path} />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex-1 bg-grey-lighter">
          <div className="inline-flex h-full w-full px-4 py-2">
            <div className="mx-auto w-full inline-block my-2 mx-2 bg-white ">
              {children}
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};
