import React from "react";
import { Link } from "gatsby";

const flexNav =
  "flex flex-row items-baseline justify-between text-center flex pt-2 px-6 border-box bg-white border-b border-grey-lighter";
const appLogo = "text-xl font-bold no-underline text-grey-darkest pb-4";
const links =
  "flex flex-col text-xl font-thin no-underline text-grey-darkest ml-4 mr-4 pb-4";
const activeLink = links + " border-b-4 border-teal";
const searchBox =
  "search-box flex flex-col w-full text-base font-thin no-underline text-grey-darkest p-2 pl-10 bg-transparent border-2 rounded-lg border-grey-lighter";

export default ({ currPage }) => {
  const isPartiallyActive = function({ href, location }) {
    const val =
      href === location.pathname ||
      "/" + href === location.pathname ||
      (href.length > 1 &&
        location.pathname !== "//" &&
        location.pathname.indexOf(href) > -1)
        ? { className: activeLink }
        : { className: links };
    return val;
  };

  // Based on observations, the error seems to be that it doesn't know the value of currPage while rendering while
  // the page is being built. As a result, none of the conditions are true, which leads to nothing being highlighted
  // when rendered from the server i.e. when you directly enter the endpoint URL instead of clicking on the links.
  return (
    // <nav className={flexNav}>
    // 	<div className="flex flex-row w-1/6">
    // 		<Link to="/" className={appLogo}>
    // 			Mind-Life Crisis
    // 		</Link>
    // 	</div>

    // 	<div className="flex flex-row items-baseline w-1/4">
    // 		<input placeholder="Search for posts" type="text" className={searchBox} />
    // 	</div>
    // 	<div className="responsive-container flex flex-row items-baseline justify-between text-center w-1/2">
    // 		{/* This checkbox will give us the toggle behavior, it will be hidden, but functional */}
    // 		<input id="toggle" type="checkbox" />
    // 		{/* IMPORTANT: Any element that we want to modify when the checkbox state changes go here, being "sibling" of the checkbox element  */}
    // 		<label className="toggle-container absolute inline-block w-8 h-8 cursor-pointer" htmlFor="toggle">
    // 			{/* If menu is open, it will be the "X" icon, otherwise just a clickable area behind the hamburger menu icon */}
    // 			<span className="button button-toggle absolute w-8 h-8" />
    // 		</label>

    // 		<div className="flex flex-row">
    // 			<Link to="/" getProps={isPartiallyActive} className={links}>
    // 				Blog
    // 			</Link>
    // 			<Link to="/writing" getProps={isPartiallyActive} className={links}>
    // 				Fiction
    // 			</Link>
    // 			<Link to="/meta" getProps={isPartiallyActive} className={links}>
    // 				Meta
    // 			</Link>
    // 		</div>
    // 	</div>
    // </nav>

    <nav className="flex items-center border-b border-grey-lighter justify-between flex-wrap pt-6 pb-4 px-4">
      <input
        type="checkbox"
        className="hidden"
        defaultChecked
        id="responsive-toggle"
      />
      <div className="flex items-center flex-no-shrink text-white mr-6">
        <Link to={`/`} className="no-underline" key={`/`}>
          <span className="font-bold text-2xl text-blue-darker tracking-tight">
            GrokOn
          </span>
        </Link>
      </div>
      <div className="block lg:hidden">
        <label
          className="flex items-center px-3 py-2 border rounded text-blue-darker border-blue-darker hover:text-blue-dark hover:border-blue-dark"
          htmlFor="responsive-toggle"
        >
          <svg
            className="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </label>
      </div>
      <div className="w-full block lg:flex-grow lg:flex lg:items-center justify-end lg:w-auto menu-items">
        {/* <div className="text-sm lg:flex-grow flex justify-between"> */}
        {/* <div className="text-sm lg:w-4/5 lg:mx-auto lg:flex block lg:flex-grow justify-start">
					<a
						href="#responsive-header"
						className="block mt-4 lg:inline-block lg:mt-0 text-blue-darker no-underline hover:text-blue-dark lg:mr-32 lg:ml-32"
					>
						Docs
					</a>
					<a
						href="#responsive-header"
						className="block mt-4 lg:inline-block lg:mt-0 text-blue-darker no-underline hover:text-blue-dark lg:mr-32"
					>
						Examples
					</a>
					<a
						href="#responsive-header"
						className="block mt-4 lg:inline-block lg:mt-0 text-blue-darker no-underline hover:text-blue-dark"
					>
						Blog
					</a>
				</div> */}
        <div>
          <a
            href="#"
            className="inline-block text-sm px-4 py-2 leading-none border rounded text-blue-darker border-blue-darker hover:border-transparent hover:text-blue-dark hover:border-blue-dark mt-4 lg:mt-0"
          >
            Subscribe
          </a>
        </div>
      </div>
    </nav>
  );
};
